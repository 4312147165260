import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FaFolder, FaFolderPlus, FaFileAlt } from "react-icons/fa";
import FolderPopup from "./FolderPopup";
import { useCreateFolder, useGetFolders } from "../../../hook/folder";

const FolderList = () => {
  const { id: currentFolderId } = useParams(); 
  const location = useLocation()

  let folerName = location.state || {}

  console.log(folerName)  
  const navigate = useNavigate(); 
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isProjectPopupOpen, setIsProjectPopupOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [newProjectName, setNewProjectName] = useState("");
  
  const { data: folders, isLoading, isError } = useGetFolders(currentFolderId);
  
  const { mutate: createFolder } = useCreateFolder();

  const handleCreateFolder = (folderName) => {
    createFolder(
      { folderName: folderName, parentId: currentFolderId },
      {
        onSuccess: () => {
          setNewFolderName("");
          setIsPopupOpen(false);
        },
      }
    );
  };

  const handleCreateProject = () => {    
    setNewProjectName("");
    setIsProjectPopupOpen(false);
  };  

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Failed to load folders or projects.</p>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">
          {folerName?.name ? `Folder: ${folerName?.name}` : "All Folders"}
        </h1>
        <div className="flex gap-2">
          <button
            onClick={() => setIsPopupOpen(true)}
            className="bg-theme-color hover:bg-theme-color text-white px-3 py-2 rounded flex items-center gap-2"
          >
            <FaFolderPlus /> Add Folder
          </button>
         
        </div>
      </div>

      {/* Folder List */}
      <div className="grid grid-cols-3 gap-4">
  {folders?.folders?.map((folder) => (
    <div
      key={folder.id}
      className="group border p-4 rounded shadow hover:shadow-lg transition cursor-pointer hover:bg-[#6378d4] hover:text-white"
      onClick={() => navigate(`/folders/${folder.id}`, { state: { name: folder.name } })}

    >
      <div className="flex items-center gap-2">
        <FaFolder className="text-[#6378d4] text-3xl group-hover:text-white transition-colors" />
        <h3 className="text-lg font-bold group-hover:text-white transition-colors">{folder.name}</h3>
      </div>
    </div>
  ))}
</div>

      {/* Project List */}
     <div className="mt-6">
     {
      folders.projects.length>0 &&
        <h2 className="text-xl font-bold mb-2">Projects</h2>
     }
        <div className="grid grid-cols-3 gap-4">
          {folders.projects.map((project) => (
            <div
              key={project.id}
              className="border p-4 rounded shadow hover:shadow-lg transition"
            >
              <div className="flex items-center gap-2">
                <FaFileAlt className="text-green-500 text-3xl" />
                <h3 className="text-lg font-bold">{project.name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div> 

      {/* Folder Popup */}
      <FolderPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onCreateFolder={handleCreateFolder}
      />

      {/* Project Popup */}
      {isProjectPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-6 relative">
            <button
              onClick={() => setIsProjectPopupOpen(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
            >
              &times;
            </button>
            <h2 className="text-lg font-bold mb-4">Create Project</h2>
            <input
              type="text"
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
              className="border border-gray-300 rounded p-2 w-full"
              placeholder="Project Name"
            />
            <button
              onClick={handleCreateProject}
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 w-full"
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FolderList;
