import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: 'http://15.206.90.174/api',
  baseURL: 'http://localhost:8080/api',
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
        
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized! Redirecting to login...');      
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
