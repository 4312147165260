import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./style/Style.scss";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import Login from "./auth/Login";
import ResetPassword from "./auth/ResetPassword";
import Wrapper from "./layout/Wrapper";
import Home from "./components/home/Home";
import Avatars from "./components/home/Avatars";
import Background from "./components/home/Background";
import Review from "./components/home/Review";
import FinalAvatars from "./components/home/FinalAvatars";
import GetStarted from "./components/home/GetStarted";
import SignUpEmail from "./auth/SignUpEmail";
import SendCode from "./auth/SendCode";
import CreateAccount from "./auth/CreateAccount";
import Welcome from "./components/home/Welcome";
import ProtectedRoute from "./helper/protectedRoute.js";
import { logoutUser, setUser } from "./redux/userSlice.js";
import { useFetchUserDetails } from "./hook/useUserDetails.js";
import FolderList from "./components/home/folder/FolderList.js";
import Projects from "./components/home/project/project.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './index.css';



function App() {
  const dispatch = useDispatch();
  
  const { data: userDetails, error, isLoading } = useFetchUserDetails();
  
  const isLoggedIn = userDetails ? true :false
  useEffect(() => {
    if (userDetails) {
      dispatch(setUser(userDetails));
    }

    if (error) {
      dispatch(logoutUser());
    }
  }, []);

  const { email, otp } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              additionalCondition={true}
              redirectToWhenLoggedIn="/home"
              redirectToWhenConditionFails="/"
            >
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              additionalCondition={true}
              redirectToWhenLoggedIn="/home"
              redirectToWhenConditionFails="/"
            >
              <ResetPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup-email"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              additionalCondition={true}
              redirectToWhenLoggedIn="/home"
              redirectToWhenConditionFails="/"
            >
              <SignUpEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/send-code"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              additionalCondition={email}
              redirectToWhenLoggedIn="/home"
              redirectToWhenConditionFails="/"
            >
              <SendCode />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-account"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              additionalCondition={email && otp}
              redirectToWhenLoggedIn="/home"
              redirectToWhenConditionFails="/"
            >
              <CreateAccount />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/create-account"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              additionalCondition={email && otp}
              redirectToWhenLoggedIn="/home"
              redirectToWhenConditionFails="/"
            >
             
            </ProtectedRoute>
          }
        />
     */}
          <Route path="/welcome" element={<Welcome />} />

        {/* <Route element={<AuthProtectedRoute />}> */}
        <Route element={<Wrapper />}>
          <Route path="/home" element={<GetStarted />} />
          <Route path="/folder" element={<FolderList />} />
          <Route path="/my-project" element={<Projects />} />
          <Route path="/folders/:id" element={<FolderList />} />

          <Route path="/home/:id" element={<Home />} />
          <Route path="/home/avtars" element={<Avatars />} />
          <Route path="/home/background" element={<Background />} />
          <Route path="/home/review-apparel" element={<Review />} />
          <Route path="/home/final-avtars" element={<FinalAvatars />} />
        </Route>
        {/* </Route> */}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />
    </BrowserRouter>
  );
}

export default App;
