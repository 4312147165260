import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice'; 
import authReducer from './authSlice'; 
import imageUploadSlice from './imageUploadSlice'; 

const store = configureStore({
  reducer: {
    user: userReducer, 
    auth:authReducer,
    imageUpload: imageUploadSlice,
  },
});

export default store;
