import React from 'react'
import { Link } from 'react-router-dom'

const Welcome = () => {
    return (
        <div className="d-flex items-center h-screen welcome">
            <div className='container'>
                <div className="row">
                    <div className="col-6">
                        <div>
                            <Link to='/' className='block'><img src='/assets/images/logo.svg' alt='logo' className='mb-4' /></Link>
                            <div className='mt-[150px]'>
                                <h1 className='text-gradinet text-[40px] font-[600]'>Welcome to Zunno Lens!</h1>
                                <p className='text-2xl col-10'>Let’s personalize your experience with a few quick question</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 offset-1">
                        <div className='bg-white shadow-[1px_0px_25.1px_#0000001A] p-5 rounded-[12px]'>
                            <h1 className='mb-5 text-[24px]'>Who will you creating for? Select all that apply?</h1>
                            <div>
                                <div className='border-[1px] cursor-pointer border-[#00000033] mb-4 hover:border-theme-color rounded hover:text-theme-color py-3 text-center'>My Self</div>
                                <div className='border-[1px] cursor-pointer border-[#00000033] mb-4 hover:border-theme-color rounded hover:text-theme-color py-3 text-center'>My Clients</div>
                                <div className='border-[1px] cursor-pointer border-[#00000033] hover:border-theme-color rounded hover:text-theme-color py-3 text-center'>My Company Institution</div>
                            </div>
                            <div className='mt-[200px]'>
                                <Link to={'/home'} className='bg-theme-color block no-underline text-center w-full text-white py-[14px] px-[20px] rounded-lg'>Next Page</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome