import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';  
import { clearState } from '../../redux/authSlice';  
import { setCategory } from '../../redux/imageUploadSlice';

const GetStarted = () => {
  const dispatch = useDispatch();  
 
  useEffect(() => {
    dispatch(clearState());  
    dispatch(setCategory(null)); 
  }, [dispatch]);

  return (
    <>
      <h1 className="text-[24px]">
        Get Started
      </h1>
      <p className="text-[#4B5563] mb-5">
        Pick on option below to try Zunno lens essential features like building your digital twin, creating seamless AI videos, or localizing hyper-realistic content in minutes
      </p>
      <div className='row justify-center gx-5'>
        <div className="col-4">
          <img src="/assets/images/png/started1.png" alt="started1" className='w-full' />
          <div className='mt-4'>
            <h1 className='text-[28px]'>Apparel</h1>
            <p className='text-[13px] text-[#4B5563]/80 mb-4'>Pick on option below to try Zunno lens essential features like building your digital twin, creating seamless AI videos, or localizing hyper-realistic content in minutes </p>
          </div>
          <Link to={'/home/apparel'} className='gradient-color w-full block text-center text-white rounded-[6px] py-[12px] text-xs'>Create</Link>
        </div>
        <div className="col-4">
          <img src="/assets/images/png/started2.png" alt="started1" className='w-full' />
          <div className='mt-4'>
            <h1 className='text-[28px]'>Non-Apparel</h1>
            <p className='text-[13px] text-[#4B5563]/80 mb-4'>Pick on option below to try Zunno lens essential features like building your digital twin, creating seamless AI videos, or localizing hyper-realistic content in minutes </p>
          </div>
          <Link to={'/home/non-apparel'} className='gradient-color w-full block text-center text-white rounded-[6px] py-[12px] text-xs'>Create</Link>
        </div>
      </div>
    </>
  );
}

export default GetStarted;
