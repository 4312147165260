import axiosInstance from "./axiosInstance";

const googleAuthApi = async (googleId) => {
  const response = await axiosInstance.get(`/auth/google?googleId=${googleId}`);
  return response.data;
};

const sendOtpApi = async (email) => {
    const response = await axiosInstance.post('/auth/send-otp', { email });
    return response.data;
  
};
const sendResetPasswordOtpApi = async (email) => {
    const response = await axiosInstance.post('/auth/send-verification-otp', { email });
    return response.data;
  
};

const verifyOtpApi = async (email, otp) => {
  const response = await axiosInstance.post(`/auth/verify-otp`, { email, otp });
  return response.data;
};


const loginApi = async (email, password) => {
  const response = await axiosInstance.post(`/auth/login`, { email, password });
  return response.data;
};


const setPasswordApi = async (email, otp, password) => {
  const response = await axiosInstance.post(`/auth/setPassword`, { email, otp, password });
  return response.data;
};

const uploadImageAPI = async (formData) => {
  const response = await axiosInstance.post(`/upload`, formData)
  return response.data
}

const fetchUserDetails = async () => {
  try {
    const response = await axiosInstance.get('user/details');
    return response.data.user;
  } catch (error) {
    throw new Error('Failed to fetch user details');
  }
};


 const updateUserDetails = async (userData) => {
  try {
    const response = await axiosInstance.put('/update-user', userData);
    return response.data;
  } catch (error) {
    throw new Error('Failed to update user details');
  }
};

export { googleAuthApi, sendOtpApi,sendResetPasswordOtpApi,loginApi, verifyOtpApi, setPasswordApi,fetchUserDetails,updateUserDetails , uploadImageAPI };  