import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className='side_menu'>
      <div className="flex items-center justify-between mb-4">
        <Navbar.Brand
          onClick={() => navigate('/')}
          className="select-none me-0 flex-grow md:flex-grow-0 d-flex gap-1 justify-content-sm-start items-center justify-content-center cursor-pointer"
        >
          <img
            src="/assets/images/logo.svg"
            className="w-[130px]"
            alt="logo"
          />
        </Navbar.Brand>
        <img src="/assets/images/svg/collapse.svg" alt="collapse" />
      </div>
      <div
        onClick={() => navigate('/home')}
        className={`${location.pathname.startsWith('/home') ? 'active' : ''} text-black hover:bg-light-purple font-[300] flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg cursor-pointer`}
      >
        <img src='/assets/images/svg/icon1.svg' className='grayscale' alt='home' /> Home
      </div>
      <div
        onClick={() => navigate('/my-project')}
        className={`${location.pathname === '/my-project' ? 'active' : ''} text-black hover:bg-light-purple font-[300] flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg cursor-pointer`}
      >
        <img src='/assets/images/svg/icon2.svg' alt='folder' /> Projects
      </div>
      <div
        onClick={() => navigate('/folder')}
        className={`${location.pathname === '/folder' ? 'active' : ''} text-black hover:bg-light-purple font-[300] flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg cursor-pointer`}
      >
        <img src='/assets/images/svg/folder-plus.svg' alt='folder' /> Folder
      </div>
      <div
        onClick={() => navigate('/subscription')}
        className={`${location.pathname === '/subscription' ? 'active' : ''} text-black hover:bg-light-purple font-[300] flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg cursor-pointer`}
      >
        <img src='/assets/images/svg/subs.svg' alt='subscription' /> Subscription
      </div>
      <div
        onClick={() => navigate('/settings')}
        className={`${location.pathname === '/settings' ? 'active' : ''} text-black hover:bg-light-purple font-[300] flex items-center gap-3 h-[48px] my-1 px-[12px] rounded-lg cursor-pointer`}
      >
        <img src='/assets/images/svg/settings.svg' alt='settings' /> Settings
      </div>
    </div>
  );
};

export default Sidebar;
