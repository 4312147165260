// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log("state",state.user)
      state.user = action.payload
    },
    logoutUser: (state) => {
      state.user = null;
      console.log('User logged out');
    },
  },
});

// Exporting the actions
export const { setUser, logoutUser } = userSlice.actions;
// Exporting the reducer as default
export default userSlice.reducer; // Default export
