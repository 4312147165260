import React from 'react'

const StarDust = () => {
    return (
        <div className='overflow-hidden h-[400px] stardust'>
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
        </div>
    )
}

export default StarDust