import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setEmail, setEnterOtp } from "../redux/authSlice";
import { useResetPasswordOtp, useSendOtp, useVerifyOtp } from "../hook/useUserDetails"; 
import { toast } from "react-toastify"; 


const SendCode = () => {
  const [timer, setTimer] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = useSelector((state) => state.auth.email);


  const { mutateAsync: verifyOtpMutation, isLoading, isError, error } = useVerifyOtp();
  
  const { mutate: sendOtpMutation, isLoading:otpSendingLoading} = useSendOtp({
    onSuccess: () => {                  
        // setIsOtpSent(true);
        // setTimer(60);
        dispatch(setEmail(email));
        toast.success("OTP send successfully.");            
    },
    onError: (error) => {    
      toast.warn(error?.response?.data?.message );          
    },
  });

  useEffect(() => {
    if (timer === 0) return;
    setIsDisabled(true);

    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          setIsDisabled(false);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleResendOtp = () => {
    if (!isDisabled) {
      console.log("OTP Resent");
      setTimer(60);
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (value && !isNaN(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      if (index < 5 && value) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      } else if (otp[index] !== "") {
        const updatedOtp = [...otp];
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    }
  };

  const isOtpComplete = otp.every((digit) => digit !== "");

  const handleSendOtp = () => {
    if (email) {
        sendOtpMutation(email)           
    }
};

  const handleSubmit = async () => {
    if (!isOtpComplete) {
      toast.warn("OTP send successfully.");
      return;
    }

    try {
      const otpValue = otp.join("");
      dispatch(setEnterOtp(otpValue));

      
      const response = await verifyOtpMutation({ email, otp: otpValue });
      console.log(response);

      if (response.success) {
        toast.success(response.message);
        console.log("OTP verified successfully:", );
        navigate("/create-account"); 
      } else {

        console.log("response data of otp",response)
        toast.warn(response.message);
        
      }
    } catch (err) {
      toast.warn(err.response.data.message);
      console.error("Error during OTP verification:", err.message);
      // alert("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="row justify-center min-h-screen gx-0">
        <div className="col-7">
          <div className="login_back">
            <h1 className="text-white d-flex items-center justify-center h-screen text-[50px]">
              Welcome to Zunno Lens!
            </h1>
          </div>
        </div>
        <div className="col-5 self-center">
          <div className="h-full">
            <div className="bg-white h-full md:p-[40px] py-[20px] px-5">
              <h1 className="md:text-[26px] text-[24px] mb-2">Check your email for a code</h1>
              <p className="text-[#000000]/50 mb-[40px] text-sm">
                We’ve sent a 6-characters code to abc@gmail.com. The code expires shortly, so please enter it soon.
              </p>
              <form>
                <div className="d-flex items-center justify-evenly">
                  {/* Input Fields for OTP */}
                  {otp.map((digit, index) => (
                    <div
                      key={index}
                      className="mb-4 w-[70px] h-[70px] justify-center flex items-center gap-3 bg-[#F1F3F5] rounded px-2 py-3 border-[1px] border-[rgba(0, 0, 0, 0.2)]"
                    >
                      <input
                        type="text"
                        id={`otp-${index}`}
                        className="bg-transparent w-full text-center text-2xl outline-none"
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)} // Handle Backspace
                        maxLength="1" // Limit each input to one digit
                      />
                    </div>
                  ))}
                </div>

                <div className="timer text-center mt-4">
                  {timer > 0 ? (
                    <p className="text-sm text-black/50">
                      Resend code in {formatTime(timer)}
                    </p>
                  ) : (
                    <p className="text-sm text-black/50">
                      You can now send a new code!
                    </p>
                  )}
                </div>

                <p className="text-center text-black/50 text-sm">
                  Didn’t get your code? <br />
                  <span                  
                    className={`text-theme-color !underline ${
                      isDisabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                    }`}
                   onClick={handleSendOtp}
                  >
                  {
                    otpSendingLoading ? "Sending..." : "Send a new one"
                  }
                    
                  </span>
                </p>

                <div className="my-5">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className={`bg-theme-color block mb-3 no-underline text-center w-full text-white py-[14px] px-[20px] rounded-lg ${
                      !isOtpComplete ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={!isOtpComplete || isLoading}
                  >
                    {isLoading ? "Verifying..." : "Verify"}
                  </button>
                  <Link
                    to={"/signup-email"}
                    type="submit"
                    className="text-theme-color border-[1px] border-theme-color block no-underline text-center w-full py-[14px] px-[20px] rounded-lg"
                  >
                    Back
                  </Link>
                </div>
              </form>
              <Link className="text-[#00000080]/50 text-center block text-sm">
                Need help? Contact us at info@zunno.io
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendCode;
