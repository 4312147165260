import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdEmail, MdLock } from "react-icons/md";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/userSlice";
import { useGoogleAuth, useLogin } from "../hook/useUserDetails";
import { googleAuthApi } from "../api/auth";
import { toast } from "react-toastify"; 

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { mutate: googleAuthMutate } = useGoogleAuth();
  const { mutateAsync: loginMutation } = useLogin();
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const responseGoogle = async (response) => {
    try {
      if (response?.code) {
        const result = await googleAuthApi(response.code);

        const user = result;

        if (user) {
          dispatch(setUser(user));
          localStorage.setItem("authToken", user?.token);
          toast.success("🎉 Login successfully");
          navigate("/home")
        }
      }
    } catch (error) {
      console.error("Google login error:", error);
      toast.error("Google login failed. Please try again.");
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: () => {
      toast.error(" Google login failed. Please try again.");
    },
    flow: "auth-code",
  });

  const handleLogin = async (email, password) => {
    try {
      const response = await loginMutation({ email, password });
      const { token, user,message } = response;

      localStorage.setItem("authToken", token);
      dispatch(setUser(user));
      toast.success(`🎉 ${message}`);
      navigate("/home")
    } catch (error) {
      console.error("Login error", error);
      toast.warn(`Invalid credentials`);      
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && password) {
      handleLogin(email, password);
    } else {
      toast.warn("Please fill details.");
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="row justify-center min-h-screen gx-0">
        <div className="col-7">
          <div className="login_back">
            <h1 className="text-white d-flex items-center justify-center h-screen text-[50px]">
              Welcome to Zunno Lens!
            </h1>
          </div>
        </div>
        <div className="col-5 self-center">
          <div className="h-full">
            <div className="bg-white h-full md:p-[40px] py-[20px] px-5">
              <Link to="/" className="block">
                <img
                  src="/assets/images/logo.svg"
                  alt="logo"
                  className="mb-4"
                />
              </Link>
              <h1 className="md:text-[26px] text-[24px] mb-2">
                Welcome to Zunno
              </h1>
              <p className="text-[#000000]/50 mb-[40px] text-xs">
                We recommend using a work email to easily collaborate with your
                team.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="mb-4 flex items-center gap-3 bg-[#F1F3F5] rounded px-2 py-3 border-[1px] border-[rgba(0, 0, 0, 0.2)]">
                  <MdEmail className="text-2xl text-[#2C3A9B]/50" />
                  <input
                    type="email"
                    placeholder="Name@work-email.com"
                    className="bg-transparent w-full outline-none"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-2 flex items-center gap-3 bg-[#F1F3F5] relative rounded px-2 py-3 border-[1px] border-[rgba(0, 0, 0, 0.2)]">
                  <MdLock className="text-2xl text-[#2C3A9B]/50" />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter Your Password"
                    className="bg-transparent w-full outline-none"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute top-5 right-4 text-black/50 cursor-pointer"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                <Link
                  to={"/reset-password"}
                  className="text-sm block hover:!underline text-black/50 text-end mb-4 cursor-pointer"
                >
                  Forgot password?
                </Link>
                <div>
                  <button
                    type="submit"
                    className="bg-theme-color block no-underline text-center w-full text-white py-[14px] px-[20px] rounded-lg"
                  >
                    Sign In
                  </button>
                </div>
              </form>
              <p className="text-center mt-3 text-black/50 text-sm">
                Don’t have an account?{" "}
                <Link
                  to="/signup-email"
                  className="text-theme-color !underline"
                >
                  Sign Up with Email
                </Link>
              </p>
              <div className="my-4 flex items-center">
                <div className="w-full h-[1px] bg-[#eaeaea]"></div>
                <span className="block text-center bg-white px-4 text-sm">
                  Or
                </span>
                <div className="w-full h-[1px] bg-[#eaeaea]"></div>
              </div>
              <div
                className="border-[1px] border-[#13131433] cursor-pointer flex items-center gap-2 justify-center rounded-lg py-2"
                onClick={googleLogin}
              >
                <img
                  src="/assets/images/svg/google.svg"
                  alt="google"
                  className="h-[30px]"
                />
                Google
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
