import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useNavigate } from 'react-router-dom';
import AvtarPhotos from './AvtarPhotos';
import BackgroundPhotos from './BackgroundPhotos';

const Background = () => {

    const [key, setKey] = useState('Cafe');
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        window.scrollTo(0, 0);
        navigate(path);
    };

    return (
        <div>
            <h1 className="text-center text-[24px]">
                Background
            </h1>
            <p className="text-[#4B5563] text-center mb-5">
                Catalog to Editorial in under 5 minutes
            </p>
            <div className='avtar_tab'>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 flex-nowrap whitespace-nowrap overflow-x-auto gap-4"
                    variant='pills'
                >
                    <Tab eventKey="Cafe" title="Cafe">
                        <BackgroundPhotos />
                    </Tab>
                    <Tab eventKey="Bar" title="Bar">
                        <BackgroundPhotos />
                    </Tab>
                    <Tab eventKey="Warehouse" title="Warehouse">
                        <BackgroundPhotos />
                    </Tab>
                    <Tab eventKey="Dessert" title="Dessert">
                        <BackgroundPhotos />
                    </Tab>
                    <Tab eventKey="Study" title="Study">
                        <BackgroundPhotos />
                    </Tab>
                    <Tab eventKey="Dessert" title="Dessert">
                        <BackgroundPhotos />
                    </Tab>
                    <Tab eventKey="Dessert" title="Dessert">
                        <BackgroundPhotos />
                    </Tab>
                    <Tab eventKey="Dessert" title="Dessert">
                        <BackgroundPhotos />
                    </Tab>                   
                    <Tab eventKey="Dessert" title="Dessert">
                        <BackgroundPhotos />
                    </Tab>                   
                </Tabs>
            </div>

            <div className='text-end flex items-center gap-2 justify-end'>
                <button onClick={() => handleNavigation('/home/avtars')} className='text-theme-color border-[1px] border-theme-color inline-block px-4 py-2 rounded-lg mt-4'>Previous</button>
                <button onClick={() => handleNavigation('/home/review-apparel')} className='bg-theme-color border-[1px] border-theme-color text-white inline-block px-4 py-2 rounded-lg mt-4'>Generate</button>
            </div>
        </div>
    )
}

export default Background