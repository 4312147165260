import axiosInstance from "./axiosInstance";

const getFolderApi = async (parentId) => {
  const response = await axiosInstance.post(`/folder/get-folders`, {parentId:parentId});
  return response.data;
};


const createfolderApi = async ({folderName,parentId}) => {
  const response = await axiosInstance.post(`/folder/create-folder`, {folderName,parentId});
  return response.data;
};




export { getFolderApi,createfolderApi};     