import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RiArrowDownSLine, RiArrowLeftSLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { uploadImageAPI } from "../../api/auth";
import { setApparelProductImage, setCategory } from "../../redux/imageUploadSlice";

const Home = () => {
  const dispatch = useDispatch();
  const {id} = useParams()
  useEffect(() => {
    if (id) {
      dispatch(setCategory(id)); 
    }
  }, [id, dispatch]);
  const apparelProductImage = useSelector(
    (state) => state.imageUpload.apparelProductImage
  );
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [cropPopupOpen, setCropPopupOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(apparelProductImage || null);
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (apparelProductImage) {
      setCroppedImage(apparelProductImage);
    }
  }, [apparelProductImage]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileSizeInKB = file.size / 1024; 
      if (fileSizeInKB < 500) {
        alert("The file size must be greater than 500 KB. Please upload a larger image.");
        return;
      }
  
      setUploadedPhoto(URL.createObjectURL(file));
      setCropPopupOpen(true);
    }
  };
  

  const handleCropImage = () => {
    const cropper = cropperRef.current.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    croppedCanvas.toBlob((blob) => {
      const file = new File([blob], "cropped_image.jpeg", {
        type: "image/jpeg",
      });
      uploadImage(file);
    });
    setCropPopupOpen(false);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("container", "cont2");
    formData.append("fileName", `apparel_${Date.now()}`);

    try {
      const response = await uploadImageAPI(formData);
      console.log(response);
      const imageUrl = response.path;
      setCroppedImage(imageUrl);
      dispatch(setApparelProductImage(imageUrl)); // Update Redux store
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleReupload = () => {
    setUploadedPhoto(null);
    setCroppedImage(null);
    dispatch(setApparelProductImage(null)); // Clear image from Redux store
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <h1 className="text-center text-[24px] relative">
        Elevate vanilla studio-shot image into stunning creative visuals
        <Link to={"/home"}>
          <RiArrowLeftSLine className="absolute left-0 top-1 text-black" />
        </Link>
      </h1>
      <p className="text-[#4B5563] text-center mb-5">
        Catalog to Editorial in under 5 minutes
      </p>

      <div className="row">
        <div className="col-8">
          {!croppedImage ? (
            <label className="block">
              <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
              <div className="border-[2px] text-center border-dashed border-[#4e00856b] rounded-[6px] py-[70px]">
                <img
                  src="/assets/images/svg/drop.svg"
                  className="mx-auto"
                  alt="drop"
                />
                <p>or drag and drop the photo into this area</p>
                <p className="text-[#5E5E5E]/50 text-[10px]">
                  You can upload files PNG, JPG, JPEG, BMP formats
                </p>
                <button className="bg-white border-[1px] text-xs border-[#CBD0DC] py-[6px] px-[12px] rounded">
                  Upload
                </button>
              </div>
            </label>
          ) : (
            <div className="relative bg-[#2C3A9B]/20 rounded-[6px] flex items-center justify-center h-[300px]">
              <button
                onClick={handleReupload}
                className="absolute top-2 right-2 bg-white text-xs text-[#2C3A9B] border border-[#CBD0DC] py-[6px] px-[12px] rounded"
              >
                Re-upload
              </button>
              <img
                src={croppedImage}
                alt="Uploaded"
                className="max-h-full max-w-full rounded"
              />
            </div>
          )}
          <div className="row mt-4">
            <div className="col-6">
              <label className="text-sm">SKU ID</label>
              <input
                type="text"
                className="w-full mt-2 border-[1px] border-[#00000033] rounded-lg bg-transparent p-2 placeholder:text-sm"
                placeholder="Enter ID"
              />
            </div>
            <div className="col-6">
              <div className="dropdown">
                <label className="text-sm">Folder</label>
                <button
                  className="dropdown-toggle flex items-center justify-between text-[#A8A3B6] text-start w-full mt-2 border-[1px] border-[#00000033] rounded-lg bg-transparent p-2 text-sm"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Select
                  <RiArrowDownSLine className="text-xl" />
                </button>
                <ul className="dropdown-menu w-full">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {cropPopupOpen && (
          <div className="crop-popup fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-4 w-[50%]">
              <h2 className="text-lg mb-4">Crop Image</h2>
              <Cropper
                src={uploadedPhoto}
                style={{ height: 400, width: "100%" }}
                ref={cropperRef}
                responsive={true}
                aspectRatio={NaN}
                autoCropArea={1}
                guides={false}
              />
              <div className="flex justify-end mt-4">
                <button
                  className="bg-theme-color text-white px-4 py-2 rounded-lg"
                  onClick={handleCropImage}
                >
                  Upload
                </button>
                <button
                  className="ml-2 bg-gray-300 px-4 py-2 rounded-lg"
                  onClick={() => setCropPopupOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="col-4">
          <div className="bg-white shadow-[0px_4px_9.6px_#0000001A] py-4 px-3 rounded-[6px]">
            {croppedImage ? (
              <>
                <h1 className="text-xl text-center mb-4">Sample Images</h1>
                <div className="relative">
                  <img
                    src={croppedImage}
                    alt="image"
                    className="mx-auto w-full rounded-[3px]"
                  />
                  <img
                    src={croppedImage}
                    alt="image"
                    className="rounded-[3px] absolute bottom-2 right-2 h-[30%] w-[30%]"
                  />
                </div>
                <p className="text-center text-theme-color text-sm mt-3">
                  More Sample images
                </p>
              </>
            ) : (
              <>
                <div className="row gy-3 gx-3">
                  <div className="col-6">
                    <img
                      src="/assets/images/png/img1.png"
                      alt="image"
                      className="rounded-[3px] w-full"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src="/assets/images/png/img2.png"
                      alt="image"
                      className="rounded-[3px] w-full"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src="/assets/images/png/img1.png"
                      alt="image"
                      className="rounded-[3px] w-full"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src="/assets/images/png/img2.png"
                      alt="image"
                      className="rounded-[3px] w-full"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src="/assets/images/png/img1.png"
                      alt="image"
                      className="rounded-[3px] w-full"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src="/assets/images/png/img2.png"
                      alt="image"
                      className="rounded-[3px] w-full"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {croppedImage && (
            <div className="text-end">
              <Link
                to={id === "apparel" ? "/home/avtars" : "/home/background"}
                className="bg-theme-color text-white inline-block px-4 py-2 rounded-lg mt-4"
              >
                Proceed
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
