import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import {  useResetPasswordOtp, useSendOtp, useVerifyOtp } from '../hook/useUserDetails';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify"; 
import { setEmail, setEnterOtp,setType } from '../redux/authSlice';

const ResetPassword = () => {
    const [email, setEmailValue] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [timer, setTimer] = useState(0);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { mutateAsync: verifyOtpMutation, isLoading:isVerifyingOtp, isError:isVerifyError, error } = useVerifyOtp();

    const { mutate: sendOtpMutation, isLoading, isError} = useResetPasswordOtp({
        onSuccess: () => {                  
            setIsOtpSent(true);
            setTimer(60);
            dispatch(setEmail(email));
            toast.success("OTP send successfully.");            
        },
        onError: (error) => {    
          toast.warn(error?.response?.data?.message );          
        },
      });

    const handleSendOtp = () => {
        if (email) {
            sendOtpMutation(email)           
        }
    };

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleOtpChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 6) {

            console.log("getting entered OTP", value)
            setOtp(value);
        }
    };
    const handleSubmit = async (e) => {       
        e.preventDefault();
        try {   
               
              
          const response = await verifyOtpMutation({ email, otp: otp });
          if (response.success) {
            toast.success(response.message);
            console.log("OTP verified successfully:", );
            dispatch(setEnterOtp(otp));
            dispatch(setType(true));
            navigate("/create-account"); 
          } else {
    
            console.log("response data of otp",response)
            toast.warn(response.message);
            
          }
        } catch (err) {
          toast.warn(err.response.data.message);
          console.error("Error during OTP verification:", err.message);
        }
      };

    return (
        <div className='container-fluid px-0'>
            <div className='row justify-center min-h-screen gx-0'>
                <div className='col-7'>
                    <div className='login_back'>
                        <h1 className='text-white d-flex items-center justify-center h-screen text-[50px]'>Welcome to Zunno Lens!</h1>
                    </div>
                </div>
                <div className='col-5 self-center'>
                    <div className='bg-white rounded-[27px] md:p-[40px] py-[20px] px-5'>
                        <Link to='/' className='block'><img src='/assets/images/logo.svg' alt='logo' className='mb-4' /></Link>
                        <h1 className='md:text-[26px] text-[24px] mb-2'>Reset your password</h1>
                        <p className='text-[#000000]/50 mb-[30px] text-sm'>We recommend using a work email to easily collaborate with your team.</p>
                        <form onSubmit={handleSubmit}>
                            <div className='mb-4 flex items-center gap-3 bg-[#F1F3F5] rounded px-2 py-3 border-[1px] border-[rgba(0, 0, 0, 0.2)]'>
                                <MdEmail className='text-2xl text-[#2C3A9B]/50' />
                                <input
                                    type='email'
                                    placeholder='Name@work-email.com'
                                    className='bg-transparent w-full outline-none'
                                    value={email}
                                    onChange={(e) => setEmailValue(e.target.value)}
                                />
                            </div>
                            <div className='flex mb-5 items-center gap-2'>
                                <div className='flex items-center gap-3 flex-1 bg-[#F1F3F5] relative rounded px-2 py-3 border-[1px] border-[rgba(0, 0, 0, 0.2)]'>
                                    <img src='/assets/images/svg/Glyph.svg' className='text-2xl text-[#2C3A9B]/50' />
                                    <input
                                        type='text'
                                        placeholder='Verification Code'
                                        className='bg-transparent w-full outline-none'
                                        value={otp}
                                        onChange={handleOtpChange}
                                        disabled={!isOtpSent || timer === 0}
                                    />
                                </div>
                                {
                                    isLoading ?(
                                        <button
      type="button"
      className={`bg-theme-color py-3 px-4 text-white rounded flex items-center justify-center ${!email && 'opacity-50 cursor-not-allowed'}`}
      disabled
    >
      <svg
        className="animate-spin h-5 w-5 mr-2 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
        ></path>
      </svg>
    </button>
                                ) :
                                    
                                    
                                    
                                    isOtpSent && timer > 0 ? (
                                    <div className='bg-gray-200 py-3 px-4 rounded'>{timer}s</div>
                                ) : (
                                    <button
                                        type="button"
                                        className={`bg-theme-color py-3 px-4 text-white rounded ${!email && 'opacity-50 cursor-not-allowed'}`}
                                        onClick={handleSendOtp}
                                        disabled={!email}
                                    >
                                        Send Code
                                    </button>
                                )}
                            </div>
                            <div>
                                <button 
                                 type="submit"                           
                                    className={`block text-center w-full text-white py-[14px] px-[20px] rounded-lg ${!(otp.length === 6 && timer > 0) && 'bg-gray-300 cursor-not-allowed'}`}
                                    style={{
                                        pointerEvents: otp.length === 6 && timer > 0 ? 'auto' : 'none',
                                        backgroundColor: otp.length === 6 && timer > 0 ? '#2C3A9B' : '#d3d3d3'
                                    }}
                                >
                                    Next Step
                                </button>
                            </div>
                        </form>
                        <p className='text-center mt-3 text-black/50 text-sm'>Already have an account? <Link to='/' className='text-theme-color !underline'>Log In</Link></p>
                        <div className='my-4 flex items-center'>
                            <div className='w-full h-[1px] bg-[#eaeaea]'></div>
                            <span className='block text-center bg-white px-4 text-sm'>Or</span>
                            <div className='w-full h-[1px] bg-[#eaeaea]'></div>
                        </div>
                        <div className='border-[1px] border-[#13131433] cursor-pointer flex items-center gap-2 justify-center rounded-lg py-2'>
                            <img src='/assets/images/svg/google.svg' alt='google' className='h-[30px]' />Google
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
