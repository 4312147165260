import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import StarDust from "./StarDust";
import { RiArrowLeftSLine } from "react-icons/ri";
import { BiLike, BiDislike } from "react-icons/bi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { FiDownload } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsQuestionCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useGenerateApperel } from "../../hook/project";

const FinalAvatars = () => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const [show1, setShow1] = useState(false);
  const toggle1 = () => setShow1(!show1);
  const [showInfo, setShowInfo] = useState(false);
  const infoRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");

  const { apparelProductImage, avatarImage, backgroundImage,categoryType } = useSelector(
    (state) => state.imageUpload
  );

  const {
    mutateAsync: generateApperelMutation,
    isLoading,
    isError,
    error,
    data,
  } = useGenerateApperel();

  useEffect(() => {
    if (apparelProductImage && backgroundImage && categoryType) {
      
      generateApperelMutation(
        { apparelProductImage, avatarImage, backgroundImage, categoryType },
        {
          onSuccess: (response) => {
            console.log("Success:", response);
      
          },
          onError: (err) => {
            console.error("Error:", err);
      
          },
        }
      );
    }
  }, [apparelProductImage, avatarImage, backgroundImage,categoryType]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        setShowInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  console.log("FINAL PAGE=---------------=========>", isLoading,
    isError,
    error,
    data,)

  return (
    <div>
      <h1 className="text-center relative text-[24px]">
        Avatars
        <Link to={"/home/review-apparel"}>
          <RiArrowLeftSLine className="absolute left-0 top-3 text-black" />
        </Link>
      </h1>
      <p className="text-[#4B5563] text-center mb-5">
        Catalog to Editorial in under 5 minutes
      </p>
      {isLoading ? (
        <div className="final_avatar">
          <div className="row justify-center">
            <div className="col-12">
              <StarDust />
            </div>
          </div>
        </div>
      )  : isError ? (
  <div className="final_avatar_error text-center py-4">
    <p className="text-red-600">
      {error?.message || "There is an error generating the image. Please try again later."}
    </p>
  </div>
)

       : data ? (
        <div className="final_avatar_back py-4 rounded-[6px]">
          <div className="row justify-center">
            <div className="col-4">
              <img src={data?.project?.image_url}  alt="image" />
              <div className="d-flex items-center gap-3 justify-end text-xl mt-3">
                <BiLike className="cursor-pointer" onClick={toggle} />
                <BiDislike className="cursor-pointer" onClick={toggle1} />
                <div className="relative" ref={infoRef}>
                  <HiOutlineDotsHorizontal
                    className="cursor-pointer"
                    onClick={() => {
                      setShowInfo(!showInfo);
                    }}
                  />
                  <div
                    className={`bg-white rounded p-3 absolute top-full duration-200 w-[320px] ${
                      showInfo
                        ? "opacity-100 visible translate-y-0"
                        : "opacity-0 invisible -translate-y-2"
                    }`}
                  >
                    <p className="text-xs d-flex items-center gap-1 mb-0">
                      Content credentials <BsQuestionCircle />
                    </p>
                    <div className="text-xs font-[600]">
                      Generated with AI ·{" "}
                      <span className="font-normal">
                        November 26, 2024 at 10:07 AM
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button className="d-flex mx-auto mt-3 items-center gap-2 bg-theme-color text-white px-3 py-2 rounded-[6px]">
                <FiDownload /> Download
              </button>
            </div>
          </div>
        </div>
      ):(
        <div className="final_avatar_error text-center py-4">
          <p>No data available. Please try again later.</p>
        </div>
      )}

    {/* like modal */}
    <Modal show={show} onHide={toggle} centered className='generate-modal'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>Submit Feedback to Zunno Lens</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-black/80'>What did you like?*</p>
                    <div>
                        <textarea cols="30" rows="5" className='border-[1px] border-[#00000033] rounded-[9px] p-3 text-sm resize-none w-full' placeholder='Give as much detail as you can, but do not include any private or sensitive information.'></textarea>

                        <div className='d-flex items-center gap-2 mt-2'>
                            <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                            Include a Screenshot
                        </div>

                        <div className='text-black/80 mt-3'>Share relevant content samples and additional log files?*</div>
                        <div className='d-flex items-center gap-4'>
                            <div className='d-flex items-center gap-2 my-2'>
                                <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                                Yes
                            </div>
                            <div className='d-flex items-center gap-2 my-2'>
                                <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                                No
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='!justify-start border-0'>
                    <Button onClick={toggle} className='border-[1px] !border-theme-color !bg-transparent !text-theme-color px-3 rounded-[6px]'>
                        Cancel
                    </Button>
                    <Button onClick={toggle} className='!bg-theme-color border-[1px] border-theme-color text-white px-3 rounded-[6px]'>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* dislike modal */}
            <Modal show={show1} onHide={toggle1} centered className='generate-modal'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>Submit Feedback to Zunno Lens</Modal.Title>
                </Modal.Header>
                <Modal.Body className='h-[500px] overflow-auto modal-scroll'>
                    <p className='text-black/80'>The Content is*</p>
                    <div>
                        <div className='d-flex items-center gap-2 mt-2'>
                            <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                            Include a Screenshot
                        </div>
                        <div className='d-flex items-center gap-2 mt-2'>
                            <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                            Hate speech, stereotyping, demeaning
                        </div>
                        <div className='d-flex items-center gap-2 mt-2'>
                            <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                            Violent: glorification of violence, self-harm
                        </div>
                        <div className='d-flex items-center gap-2 mt-2'>
                            <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                            Sexual: explicit content, grooming
                        </div>
                        <div className='d-flex items-center gap-2 mt-2'>
                            <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                            Manipulative: devices,emotional, pushy, bullying
                        </div>
                    </div>

                    <p className='text-black/80 mt-4'>Tell us more</p>

                    <div>
                        <textarea cols="30" rows="5" className='border-[1px] border-[#00000033] rounded-[9px] p-3 text-sm resize-none w-full' placeholder='Give as much detail as you can, but do not include any private or sensitive information.'></textarea>

                        <div className='d-flex items-center gap-2 mt-2'>
                            <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                            Include a Screenshot
                        </div>

                        <div className='text-black/80 mt-3'>Share relevant content samples and additional log files?*</div>
                        <div className='d-flex items-center gap-4'>
                            <div className='d-flex items-center gap-2 my-2'>
                                <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                                Yes
                            </div>
                            <div className='d-flex items-center gap-2 my-2'>
                                <input type="checkbox" className='w-[16px] h-[16px] accent-theme-color' />
                                No
                            </div>
                        </div>
                    </div>

                    <p className='text-black/50 mt-3 text-sm'>Signed in as abc@gmail.com. <Link to={'#learn'} className='text-theme-color !underline'>Learn more</Link> about how this data is used and your right.By pressing Submit, your feedback will be used to improve Zunno lens products and Services. <Link to={'#learn'} className='text-theme-color !underline'>Privacy Statement</Link></p>
                </Modal.Body>
                <Modal.Footer className='!justify-start border-0'>
                    <Button onClick={toggle1} className='border-[1px] !border-theme-color !bg-transparent !text-theme-color px-3 rounded-[6px]'>
                        Cancel
                    </Button>
                    <Button onClick={toggle1} className='!bg-theme-color border-[1px] border-theme-color text-white px-3 rounded-[6px]'>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
    </div>
  );
};

export default FinalAvatars;
