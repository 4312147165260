import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { setEmail } from '../redux/authSlice'; 
import { useSendOtp } from '../hook/useUserDetails';
import { toast } from "react-toastify"; 

const SignUpEmail = () => {
  const [email, setEmailState] = useState('');
  const [error, setErrorState] = useState(''); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  const handleEmailChange = (e) => {
    setEmailState(e.target.value);
    setErrorState(''); 
  };


  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isEmailValid = emailRegex.test(email);

  const { mutate: sendOtpMutation, isLoading, isError} = useSendOtp({
    onSuccess: () => {      
      dispatch(setEmail(email));
      toast.success("OTP send successfully.");
      navigate('/send-code'); 
    },
    onError: (error) => {

      toast.warn(error?.response?.data?.message || 'Failed to send OTP. Please try again.');
      
    },
  });

  const handleSendCode = () => {
    if (isEmailValid) {
      sendOtpMutation(email); 
    } else {
      toast.warn('Invalid email address.');
      
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="row justify-center min-h-screen gx-0">
        <div className="col-7">
          <div className="login_back">
            <h1 className="text-white d-flex items-center justify-center h-screen text-[50px]">Welcome to Zunno Lens!</h1>
          </div>
        </div>
        <div className="col-5 self-center">
          <div className="h-full">
            <div className="bg-white h-full md:p-[40px] py-[20px] px-5">
              <Link to="/" className="block">
                <img src="/assets/images/logo.svg" alt="logo" className="mb-4" />
              </Link>
              <h1 className="md:text-[26px] text-[24px] mb-2">Welcome to Zunno</h1>
              <p className="text-[#000000]/50 mb-[40px] text-sm">
                We recommend using a work email to easily collaborate with your team.
              </p>
              <form>
                <div className="mb-4 flex items-center gap-3 bg-[#F1F3F5] rounded px-2 py-3 border-[1px] border-[rgba(0, 0, 0, 0.2)]">
                  <MdEmail className="text-2xl text-[#2C3A9B]/50" />
                  <input
                    type="email"
                    placeholder="Name@work-email.com"
                    className="bg-transparent w-full outline-none"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                {error && <p className="text-red-500 text-center">{error}</p>}
                <div>
                  <button
                    type="button"
                    onClick={handleSendCode}
                    className={`bg-theme-color block no-underline text-center w-full text-white py-[14px] px-[20px] rounded-lg ${
                      !isEmailValid || isLoading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={!isEmailValid || isLoading}
                  >
                    {isLoading ? 'Sending...' : 'Send Code'}
                  </button>
                </div>
              </form>
              <p className="text-center mt-3 text-black/50 text-sm">
                Already have an account? <Link to="/" className="text-theme-color !underline">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpEmail;
