import axiosInstance from "./axiosInstance";

const createProjectApi = async (
  avatarImage,
  apparelProductImage,
  backgroundImage,
  categoryType
) => {
  try {
    console.log("API Request:", {
      avatarImage,
      apparelProductImage,
      backgroundImage,
      categoryType
    });

    const response = await axiosInstance.post("/project/create-project", {
      avatarImage,
      apparelProductImage,
      backgroundImage,
      categoryType
    });

    console.log("API Response:", response.data);

    return response.data;
  } catch (error) {
    console.error("Error creating project:", error);

    if (error.response) {
      console.error("API Error Response:", error.response);
    } else {
      console.error("Network Error:", error.message);
      throw new Error("Network error occurred. Please try again.");
    }
  }
};

const getProjectApi = async () => {
  const response = await axiosInstance.get(`/project/get-projects`);
  return response.data;
};

export { getProjectApi, createProjectApi };
