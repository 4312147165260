
import { useMutation,useQuery } from 'react-query';
import { createProjectApi, getProjectApi } from '../api/project';


export const useGenerateApperel = () => {
    return useMutation(
      ({ avatarImage, apparelProductImage, backgroundImage,categoryType }) =>
        createProjectApi(avatarImage, apparelProductImage, backgroundImage,categoryType),
      {
        onSuccess: (data) => {
          console.log('Project created successfully:', data);
        },
        onError: (error) => {
          console.error('Error occurred during project creation:', error.message);
        },
      }
    );
  };


  export const useGetProjects = () => {
    return useQuery({
      queryKey: ['projects'], // Unique key to cache/query the project data
      queryFn: () => getProjectApi(), // Fetch function
    });
  };