import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import FeedbackModal from '../common/FeedbackModal';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, setUser } from '../redux/userSlice';
import { toast } from "react-toastify";

const Header = () => {

    const dropdownRef = useRef(null);
    const notificationRef = useRef(null);
    const [profile, setProfile] = useState(false);
    const [notification, setNotification] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState("");
    const [show1, setShow1] = useState(false);
    const dispatch =useDispatch()
    const toggle = () => { setShow1(!show1); };

    const data = useSelector((state) => state.user);

    console.log("data....", data)

    const handleLogout = ()=>{
        dispatch(logoutUser());
        localStorage.removeItem("authToken");
        toast.success("Logout Successfully")
        window.location.href = "/";

    }

    const profileToggle = () => {
        if (activeDropdown === "profile") {
            setActiveDropdown("");
            setProfile(false);
        } else {
            setActiveDropdown("profile");
            setProfile(true);
            setNotification(false);
        }
    };
    const notificationToggle = () => {
        if (activeDropdown === "notification") {
            setActiveDropdown("");
            setNotification(false);
        } else {
            setActiveDropdown("notification");
            setNotification(true);
            setProfile(false);
        }
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            notificationRef.current &&
            !notificationRef.current.contains(event.target)
        ) {
            setActiveDropdown("");
            setNotification(false);
            setProfile(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className='bg-white border-b'>
            <Navbar expand="lg " className="!py-[14px]">
                <Container fluid className="!px-[60px] justify-end">
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    <div className="flex items-center gap-3 ms-auto">
                        <div className="flex cursor-pointer relative group text-sm items-center gap-1">
                            <img
                                src="/assets/images/svg/coin.svg"
                                className="w-[24px]"
                                alt="coin"
                            />
                            {data?.user?.coins}
                            <div className="bg-[#616161] p-2 rounded-lg text-[12px] w-[180px] duration-300 opacity-0 invisible group-hover:!opacity-100 group-hover:!visible d-md-block d-none text-white absolute -right-10 top-[170%]">
                                You have {data?.user?.coins} boosts left today
                            </div>
                        </div>

                        <div className="group rounded-full d-sm-flex hidden justify-center items-center relative cursor-pointer duration-300" ref={notificationRef}>
                            <img src="/assets/images/png/notification.png" className="w-[20px]" onClick={notificationToggle} />
                            <div className="bg-[#616161] p-2 rounded-lg text-[12px] duration-300 opacity-0 invisible group-hover:!opacity-100 group-hover:!visible text-white absolute top-[195%]">
                                Notifications
                            </div>
                            <div
                                ref={dropdownRef}
                                className={`${notification ? "opacity-100 visible" : "opacity-0 invisible"
                                    } duration-500 bg-white shadow w-[400px] p-3 absolute top-full mt-2 rounded-lg right-0`}
                            >
                                <h1 className="text-2xl text-center">Notifications</h1>
                                <img src="/assets/images/svg/notification.svg" alt="notification" className='mx-auto my-4' />
                                <p className='text-theme-color/80 text-center'>You don’t have any notification</p>

                            </div>
                        </div>

                        <div
                            onClick={toggle}
                            className="text-black relative cursor-pointer group d-sm-flex hidden items-center justify-center rounded-full"
                        >
                            <img
                                src="/assets/images/svg/feedback.svg"
                                className="w-[20px]"
                                alt="feedback"
                            />
                            <div className="bg-[#616161] p-2 rounded-lg text-[12px] duration-300 opacity-0 invisible group-hover:!opacity-100 group-hover:!visible text-white absolute top-[180%]">
                                Feedback
                            </div>
                        </div>

                        <div className="relative d-sm-block hidden" ref={dropdownRef}>
                            <div onClick={profileToggle} className="w-[32px] h-[32px] text-theme-color border-[1px] border-theme-color font-bold bg-white rounded-full flex items-center justify-center cursor-pointer">
                            {data?.user?.name?.split("")[0]}
                            </div>
                            <div
                                className={`${profile ? "opacity-100 visible" : "opacity-0 invisible"
                                    } duration-500 bg-white shadow w-[300px] p-3 absolute top-full mt-2 rounded-lg right-0`}
                            >
                                <div className="flex items-center gap-3 border-b pb-3 mb-3">
                                    <div className="w-[50px] h-[50px] text-xl border-[1px] border-theme-color font-bold text-theme-color rounded-full flex items-center justify-center cursor-pointer">
                                    {data?.user?.name?.split("")[0]}
                                    </div>
                                    <div className="flex flex-col">
                                        <b>{data?.user?.name}</b>
                                        <span>{data?.user?.email}</span>
                                    </div>
                                </div>
                                {/* <a href='#new' className='text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-theme'>What's new</a> */}
                                <a
                                    href="/accounts"
                                    className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-purple"
                                >
                                    Account
                                </a>
                                <a
                                    href="/purchase-history"
                                    className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-purple"
                                >
                                    Billing Central
                                </a>
                                <a
                                    href="#report"
                                    className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-purple"
                                >
                                    Report content
                                </a>
                                <a
                                    href="#report"
                                    className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-purple"
                                >
                                    About Us
                                </a>
                                <a
                                    href="#refer"
                                    className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-purple"
                                >
                                    Refer Zunno
                                </a>
                                <a
                                    href="/setting"
                                    className="text-black flex items-center rounded-lg my-1 h-[40px]  px-[12px] hover:bg-light-purple"
                                >
                                    Settings
                                </a>
                                <div className="my-1 border-t" ></div>
                                <span
                                    onClick={handleLogout}
                                    className="text-black flex items-center rounded-lg py-1 h-[40px]  px-[12px] hover:bg-light-purple cursor-pointer"
                                    
                                >
                                    Sign out of Zunno
                                </span>
                            </div>
                        </div>

                        {/* <div onClick={barToggle} className="d-sm-none">
                            <img src="/assets/images/dots.svg" alt="dots" />
                        </div> */}
                    </div>
                </Container>
            </Navbar>

            <FeedbackModal show={show1} toggle={toggle} />
        </div>
    )
}

export default Header