import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const Review = () => {


    const {apparelProductImage , avatarImage , backgroundImage, } = useSelector((state) => state.imageUpload);
    // const {apparelProductImage , avatarImage , backgroundImage, } = useSelector((state) => state.imageUpload);
    // const {apparelProductImage , avatarImage , backgroundImage, } = useSelector((state) => state.imageUpload);

    return (


        <div>
            <h1 className="text-center text-[24px]">
                Review Apparel Mask and Model/Background Selection
            </h1>
            <p className="text-[#4B5563] text-center mb-5">
                Catalog to Editorial in under 5 minutes
            </p>
            <div className='bg-white rounded-[6px] px-[25px] py-4'>
                <div className="row">
                    <div className="col-4">
                        <div className='bg-theme-color/10 relative rounded-[6px] h-full overflow-hidden'>
                            <img src={apparelProductImage} alt="images" className='mx-auto w-full' />
                            <div className='absolute top-3 right-3 bg-white text-[10px] rounded py-[4px] px-[8px]'>Mask Apparel</div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='bg-theme-color/10 relative d-flex rounded-[6px] h-full overflow-hidden'>
                            <img src={avatarImage} alt="images" className='mx-auto w-full self-end' />
                            <div className='absolute top-3 right-3 bg-white text-[10px] rounded py-[4px] px-[8px]'>Model</div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='bg-theme-color/10 relative rounded-[6px] h-full overflow-hidden'>
                            <img src={backgroundImage} alt="images" className='mx-auto w-full' />
                            <div className='absolute top-3 right-3 bg-white text-[10px] rounded py-[4px] px-[8px]'>Background</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-end flex items-center gap-2 justify-end'>
                <Link to='/home/background' className='text-theme-color border-[1px] border-theme-color inline-block px-4 py-2 rounded-lg mt-4'>Previous</Link>
                <Link to='/home/final-avtars' className='bg-theme-color border-[1px] border-theme-color text-white inline-block px-4 py-2 rounded-lg mt-4'>Generate</Link>
            </div>
        </div>
    )
}

export default Review