import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: null,
  otp: null,
  type:null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      console.log('Action payload for setEmail:', action.payload);
      state.email = action.payload;
      console.log('Updated state.email:', state.email);
    },
    setEnterOtp: (state, action) => {
      console.log('Action payload for setEnterOtp:', action.payload);
      state.otp = action.payload; 
      console.log('Updated state.otp:', state.otp);
    },
    setType: (state, action) => {
      console.log('Action payload for setEnterOtp:', action.payload);
      state.type = action.payload; 
      console.log('Updated state.otp:', state.type);
    },
    clearState: (state) => {
      console.log('Clearing state...');
      state.email = null;
      state.otp = null;
      state.type=null
      console.log('State after clear:', state);
    },
  },
});

export const { setEmail, setEnterOtp,setType, clearState } = authSlice.actions;
export default authSlice.reducer;
