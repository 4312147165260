import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useSetPassword } from "../hook/useUserDetails"; 
import { setUser } from "../redux/userSlice";
import { toast } from "react-toastify"; 

const CreateAccount = () => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [password, setPassword] = useState("");  
  const [confirmPassword, setConfirmPassword] = useState("");  
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [passwordError, setPasswordError] = useState(""); 
  const [confirmPasswordError, setConfirmPasswordError] = useState(""); 
  const navigate = useNavigate();
  const { email, otp,type } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { mutateAsync: passwordMutation, isLoading, isError, error } = useSetPassword(); 

  const validatePassword = (password) => {
    if (password.length < 8 || password.length > 35) {
      return "Password must be between 8 and 35 characters long";
    }
    if (!/[A-Z]/.test(password)) {
      return "Password must include at least one uppercase letter";
    }
    if (!/[a-z]/.test(password)) {
      return "Password must include at least one lowercase letter";
    }
    if (!/\d/.test(password)) {
      return "Password must include at least one number";
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return "Password must include at least one special character";
    }
    return ""; 
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      return "Passwords do not match";
    }
    return ""; 
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
  
    const error = validateConfirmPassword(password, value);
    setConfirmPasswordError(error);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    const error = validatePassword(value);
    setPasswordError(error); 
  };

  const isFormValid =
    password &&
    confirmPassword &&
    passwordError === "" &&
    confirmPasswordError === "" &&
    checkboxChecked;


    const handleLogin = async (email,otp, password) => {
      try {
        console.log("print the email and password",email,otp,password)
        const response = await passwordMutation({ email, otp, password });            
        const { token,message, user } = response;
        toast.success(message);
        localStorage.setItem("authToken", token);      
        dispatch(setUser(user));

        type ? navigate("/") :navigate("/home")

       
      } catch (error) {
        toast.warn(error);
        console.error("Login error", error);
        
      }
    };





  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const passwordValidationError = validatePassword(password);
    const confirmPasswordValidationError = validateConfirmPassword();

    if (passwordValidationError || confirmPasswordValidationError) {
      setPasswordError(passwordValidationError);
      setConfirmPasswordError(confirmPasswordValidationError);
      return;
    }

    handleLogin(email,otp,confirmPassword)
   
  };

  return (
    <div className="container-fluid px-0">
      <div className="row justify-center min-h-screen gx-0">
        <div className="col-7">
          <div className="login_back">
            <h1 className="text-white d-flex items-center justify-center h-screen text-[50px]">
              Welcome to Zunno Lens!
            </h1>
          </div>
        </div>
        <div className="col-5 self-center">
          <div className="h-full">
            <div className="bg-white h-full md:p-[40px] py-[20px] px-5">
              <Link to="/" className="block">
                <img
                  src="/assets/images/logo.svg"
                  alt="logo"
                  className="mb-3"
                />
              </Link>
              <p className="text-[#000000]/50 mb-3 text-sm">
                We recommend using a work email to easily collaborate with your
                team.
              </p>
              <p className="text-[#000000]/50 mb-[40px] text-sm font-[500]">
                Email: {email}
              </p>
              <form onSubmit={handleSubmit}>
                {/* Password input */}
                <div className="mb-4 flex items-center gap-3 bg-[#F1F3F5] relative rounded px-2 py-3 border-[1px] border-[rgba(0, 0, 0, 0.2)]">
                  <MdLock className="text-2xl text-[#2C3A9B]/50" />
                  <input
                    type={showPassword.password ? "text" : "password"}
                    placeholder="New Password"
                    className="bg-transparent w-full outline-none"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <span
                    onClick={() =>
                      setShowPassword((prev) => ({
                        ...prev,
                        password: !prev.password,
                      }))
                    }
                    className="absolute top-5 right-4 text-black/50 cursor-pointer"
                  >
                    {showPassword.password ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                {passwordError && password.length > 0 && (
                  <p className="text-[13px] -mt-5 text-red-500">{passwordError}</p>
                )}

                {/* Confirm Password input */}
                <div className="mb-5 flex items-center gap-3 bg-[#F1F3F5] relative rounded px-2 py-3 border-[1px] border-[rgba(0, 0, 0, 0.2)]">
                  <MdLock className="text-2xl text-[#2C3A9B]/50" />
                  <input
                    type={showPassword.confirmPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    className="bg-transparent w-full outline-none"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <span
                    onClick={() =>
                      setShowPassword((prev) => ({
                        ...prev,
                        confirmPassword: !prev.confirmPassword,
                      }))
                    }
                    className="absolute top-5 right-4 text-black/50 cursor-pointer"
                  >
                    {showPassword.confirmPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                {confirmPasswordError && confirmPassword.length > 0 && (
                  <p className="text-[13px] -mt-5 text-red-500">{confirmPasswordError}</p>
                )}

                {/* Terms and conditions */}
                <div className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    checked={checkboxChecked}
                    onChange={(e) => setCheckboxChecked(e.target.checked)}
                  />
                  <label className="ml-2 text-sm">
                    I agree to the <span className="text-theme-color">Terms & Conditions</span>
                  </label>
                </div>

                {/* Submit button */}
                <button
                  type="submit"
                  className={`bg-theme-color mb-3 block no-underline text-center w-full text-white py-[14px] px-[20px] rounded-lg ${
                    !isFormValid || isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={!isFormValid || isLoading}
                >
                  {isLoading ? "Setting Password..." : type ? "Reset Password" : "Create Account"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
