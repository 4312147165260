import React, { useState, useEffect, useRef } from "react";
import { useGetProjects } from "../../../hook/project";

const Projects = () => {
  const { data, isLoading, isError, error } = useGetProjects();
  const [menuOpenProjectId, setMenuOpenProjectId] = useState(null); // To track which project menu is open
  const menuRef = useRef(null); // To track the menu
  const buttonRef = useRef(null); // To track the button (three dots)

  // Close the menu if the user clicks outside the button or menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current && !menuRef.current.contains(event.target) &&
        buttonRef.current && !buttonRef.current.contains(event.target)
      ) {
        setMenuOpenProjectId(null); // Close the menu
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (isLoading) {
    return <div className="text-[#2C3A9B]">Loading projects...</div>;
  }

  if (isError) {
    return <div className="text-red-500">Error: {error.message || "Failed to fetch projects"}</div>;
  }

  const projects = data?.projects || [];

  // Toggle the menu visibility
  const toggleMenu = (id) => {
    setMenuOpenProjectId((prevId) => (prevId === id ? null : id));
  };

  // Handle option select
  const handleOptionSelect = (option, project) => {
    if (option === "Copy Link") {
      // Copy the image_url to the clipboard
      navigator.clipboard.writeText(project.image_url).then(() => {
        alert("Image URL copied to clipboard!");
      }).catch((err) => {
        alert("Failed to copy the URL: " + err);
      });
    } else {
      console.log(`Option selected: ${option} for project: ${project.description}`);
    }
    setMenuOpenProjectId(null); // Close the menu after option is selected
  };

  // Open image in fullscreen
  const openFullscreen = (imageUrl) => {
    const imgWindow = window.open('', '', 'width=800,height=600');
    imgWindow.document.write(`<img src="${imageUrl}" style="width:100%; height:100%; object-fit:contain;" />`);
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-[#2C3A9B]">My Projects</h1>
      {projects.length === 0 ? (
        <p>No projects found.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {projects.map((project) => (
            <div
              key={project._id}
              className="relative border border-[#6378d4]  rounded-lg shadow-lg bg-gradient-to-r from-[#6d7dc3] to-[#33419c]  hover:bg-[#E0E6F9] transition duration-300 ease-in-out"
            >
              {/* Project Image */}
              <img
                src={project.image_url}
                alt={project.description || "Project Image"}
                className="w-full h-48 object-cover rounded-t-lg"
              />

              {/* Three Dots Menu Button */}
              <div className="absolute top-2 right-2">
                <button
                  ref={buttonRef}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu(project._id);
                  }}
                  className="text-[#2C3A9B] text-2xl focus:outline-none hover:text-[#6378d4]"
                >
                  ⋮
                </button>
              </div>

              {/* Dropdown Menu */}
              {menuOpenProjectId === project._id && (
                <div
                  ref={menuRef}
                  className="absolute top-2 -right-48 bg-gradient-to-r from-[#6378d4] to-[#2C3A9B] shadow-xl rounded-md py-2 w-48 z-10 transition-all transform opacity-100 scale-100 duration-300"
                  style={{
                    opacity: menuOpenProjectId === project._id ? 1 : 0,
                    transform: menuOpenProjectId === project._id ? 'scale(1)' : 'scale(0.8)',
                    transition: 'opacity 0.3s ease, transform 0.3s ease',
                  }}
                >
                  <button
                    onClick={() =>
                      handleOptionSelect("Move to the folder", project)
                    }
                    className="block px-4 py-2 text-left text-white hover:bg-[#2C3A9B] w-full rounded-md"
                  >
                    Move to the folder
                  </button>
                  <button
                    onClick={() =>
                      handleOptionSelect("Move to Trash", project)
                    }
                    className="block px-4 py-2 text-left text-white hover:bg-[#2C3A9B] w-full rounded-md"
                  >
                    Move to Trash
                  </button>
                  <button
                    onClick={() => handleOptionSelect("Copy Link", project)}
                    className="block px-4 py-2 text-left text-white hover:bg-[#2C3A9B] w-full rounded-md"
                  >
                    Copy Link
                  </button>
                  <button
                    onClick={() => openFullscreen(project.image_url)}
                    className="block px-4 py-2 text-left text-white hover:bg-[#2C3A9B] w-full rounded-md"
                  >
                    View
                  </button>
                </div>
              )}

              {/* Project Description */}
              <div className="p-4 from-[#6378d4] to-[#2C3A9B] ">
                <h2 className="text-xl font-semibold text-white hover:text-[#6378d4] transition duration-300 ease-in-out">
                  {project.description || "Untitled Project"}
                </h2>
                <p className="text-sm text-white ">
                  Created on: {new Date(project.createdAt).toLocaleDateString()}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Projects;
