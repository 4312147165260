import React, { useState } from "react";

const FolderPopup = ({ isOpen, onClose, onCreateFolder }) => {
  const [folderName, setFolderName] = useState("Untitled folder");

  if (!isOpen) return null;

  const handleContinue = () => {
    if (folderName.trim()) {
      onCreateFolder(folderName);
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-6 relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          &times;
        </button>

        {/* Heading */}
        <h2 className="text-lg font-bold mb-4">Create Folder</h2>

        {/* Input */}
        <input
          type="text"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          className="border border-gray-300 rounded p-2 w-full"
          placeholder="Enter folder name"
        />

        {/* Continue Button */}
        <button
          onClick={handleContinue}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 w-full"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default FolderPopup;
