
import { useMutation, useQuery } from 'react-query';
import { googleAuthApi, sendOtpApi, verifyOtpApi, setPasswordApi , fetchUserDetails, updateUserDetails, loginApi, sendResetPasswordOtpApi } from '../api/auth';

export const useGoogleAuth = () => {
  return useMutation({
    mutationFn: (googleId) => googleAuthApi(googleId),  
    onSuccess: (data) => {
      console.log("Google Auth successful:", data);
      
    },
    onError: (error) => {
      
      console.error("Error in Google Auth:", error);
    }
  });
};


export const useSendOtp = ({ onSuccess, onError }) => {
  return useMutation((email) => sendOtpApi(email), {
    onSuccess,
    onError,
  });
};
export const useResetPasswordOtp = ({ onSuccess, onError }) => {
  return useMutation((email) => sendResetPasswordOtpApi(email), {
    onSuccess,
    onError,
  });
};


export const useVerifyOtp = () => {
  return useMutation(({ email, otp }) => verifyOtpApi(email, otp));
};


export const useLogin = () => {
  return useMutation(({ email, password }) => loginApi(email, password));
};


export const useSetPassword = () => {
  return useMutation(({ email, otp, password }) => setPasswordApi(email, otp, password));
};


export const useFetchUserDetails = () => {
  return useQuery('userDetails', fetchUserDetails);
};


export const useUpdateUserDetails = () => {
  return useMutation((userData) => updateUserDetails(userData));
};






// export const useUserDetails = () => {
//   return useQuery(['userDetails'], fetchUserDetails, {
//     onError: (error) => {
//       console.error('Error fetching user details:', error);

//     },
//     retry: 2, 
//     refetchOnWindowFocus: false, 
//   });
// };
