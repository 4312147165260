import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getFolderApi, createfolderApi } from '../api/folder'; 


export const useGetFolders = (parentId) => {
  return useQuery({
    queryKey: ['folders', parentId],
    queryFn: () => getFolderApi(parentId),
  });
};


export const useCreateFolder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createfolderApi,
    onSuccess: () => {
      queryClient.invalidateQueries(['folders']);
    },
    onError: (error) => {
      console.error('Failed to create folder:', error);
    },
  });
};
