import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isLoggedIn, additionalCondition, redirectToWhenLoggedIn, redirectToWhenConditionFails, children }) => {
    if (isLoggedIn) {
        
        return <Navigate to={redirectToWhenLoggedIn} replace />;
    }

    if (!additionalCondition) {
        return <Navigate to={redirectToWhenConditionFails} replace />;
    }

    return children;
};

export default ProtectedRoute;
