import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AvtarPhotos from './AvtarPhotos';
import { Link, useNavigate } from 'react-router-dom';

const Avatars = () => {

    const [key, setKey] = useState('Black');
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        window.scrollTo(0, 0);
        navigate(path);
    };

    return (
        <div>
            <h1 className="text-center text-[24px]">
                Avatars
            </h1>
            <p className="text-[#4B5563] text-center mb-5">
                Catalog to Editorial in under 5 minutes
            </p>
            <div className='avtar_tab'>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 justify-center gap-4"
                    variant='pills'
                >
                    <Tab eventKey="Black" title="Black">
                        <AvtarPhotos />
                    </Tab>
                    <Tab eventKey="White" title="White">
                        <AvtarPhotos />
                    </Tab>
                    <Tab eventKey="Brown" title="Brown">
                        <AvtarPhotos />
                    </Tab>
                    <Tab eventKey="Asian" title="Asian">
                        <AvtarPhotos />
                    </Tab>
                </Tabs>
            </div>

            <div className='text-end flex items-center gap-2 justify-end'>
                <button onClick={() => handleNavigation('/home/create')} className='text-theme-color border-[1px] border-theme-color inline-block px-4 py-2 rounded-lg mt-4'>Previous</button>
                <button onClick={() => handleNavigation('/home/background')} className='bg-theme-color border-[1px] border-theme-color text-white inline-block px-4 py-2 rounded-lg mt-4'>Next</button>
            </div>
        </div>
    )
}

export default Avatars