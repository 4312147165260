import React, { useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { Navigate, Outlet } from 'react-router-dom';
import { useFetchUserDetails } from '../hook/useUserDetails';
import { useDispatch } from 'react-redux';
import { logoutUser, setUser } from '../redux/userSlice';

const Wrapper = () => {
    const dispatch = useDispatch();
    const isLoggedIn = !!localStorage.getItem("authToken");
    const { data: userDetails, error, isLoading } = useFetchUserDetails();

    console.log(userDetails)//
    useEffect(() => {
        console.log("wrapppper data", userDetails)
        if (userDetails) {
          dispatch(setUser(userDetails));
        }
    
        if (error) {
          dispatch(logoutUser());
        }
      }, [userDetails]);
    
    const isAuthenticated = () => {
        return !!localStorage.getItem('authToken'); 
    };
    
    if (!isAuthenticated()) {
        return <Navigate to="/" replace />;
    }

    // useEffect(() => {
    //   if (userDetails) {
    //     dispatch(setUser(userDetails));
    //   }
  
    //   if (error) {
    //     dispatch(logoutUser());
    //   }
    // }, []);


    return (
        <>
            <div className="bg-[#F8F8F8] min-h-screen flex">
                <div className="w-[270px] flex-shrink-0 h-screen bg-white p-3 border-r">
                    <Sidebar />
                </div>
                <div className="flex-grow">
                    <Header />
                    <main className="h-[calc(100vh-64px)] overflow-auto py-3 px-5">
                        <Outlet />
                    </main>
                </div>
            </div>
        </>
    );
};

export default Wrapper;
