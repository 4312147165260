import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  apparelProductImage: null,
  avatarImage: null,
  backgroundImage: null,
  categoryType:null
};

const imageUploadSlice = createSlice({
  name: 'imageUpload',
  initialState,
  reducers: {
    setApparelProductImage: (state, action) => {
      console.log('Setting apparel product image:', action.payload);
      state.apparelProductImage = action.payload;
    },
    setAvatarImage: (state, action) => {
      console.log('Setting avatar image:', action.payload);
      state.avatarImage = action.payload;
    },
    setBackgroundImage: (state, action) => {
      console.log('Setting background image:', action.payload);
      state.backgroundImage = action.payload;
    },   
    resetApparelProductImage: (state) => {
      console.log('Resetting apparel product image');
      state.apparelProductImage = null;
    },
    resetAvatarImage: (state) => {
      console.log('Resetting avatar image');
      state.avatarImage = null;
    },
    resetBackgroundImage: (state) => {
      console.log('Resetting background image');
      state.backgroundImage = null;
    },
    setCategory: (state, action) => {
      console.log('Setting background image:', action.payload);
      state.apparelProductImage=null;
      state.avatarImage=null;
      state.backgroundImage = null;
      state.categoryType = action.payload;
    },
  },
});

// Exporting the actions
export const {
  setApparelProductImage,
  setAvatarImage,
  setBackgroundImage,
  resetApparelProductImage,
  resetAvatarImage,
  resetBackgroundImage,
  setCategory
} = imageUploadSlice.actions;

// Exporting the reducer as default
export default imageUploadSlice.reducer;
