import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadImageAPI } from "../../api/auth";
import { setAvatarImage } from "../../redux/imageUploadSlice";
import { Cropper } from "react-cropper";

const   AvtarPhotos = () => {
    const dispatch = useDispatch();
    const avatarImage = useSelector((state) => state.imageUpload.avatarImage);
    const [uploadedPhoto, setUploadedPhoto] = useState(null);
    const [cropPopupOpen, setCropPopupOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState(avatarImage || null);
    const cropperRef = useRef(null);
    const fileInputRef = useRef(null);
  
    useEffect(() => {
      
      if (avatarImage) {
        setCroppedImage(avatarImage);
      }
    }, [avatarImage]);
  
    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        setUploadedPhoto(URL.createObjectURL(file));
        setCropPopupOpen(true);
      }
    };
  
    const handleCropImage = () => {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();
      croppedCanvas.toBlob((blob) => {
        const file = new File([blob], "cropped_image.jpeg", { type: "image/jpeg" });
        uploadImage(file);
      });
      setCropPopupOpen(false);
    };
  
    const uploadImage = async (file) => {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("container", "cont2");
      formData.append("fileName", `apparel_${Date.now()}`);
  
      try {
        const response = await uploadImageAPI(formData);
        console.log(response);
        const imageUrl = response.path;
        setCroppedImage(imageUrl);
        dispatch(setAvatarImage(imageUrl)); 
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };
  
    const handleReupload = () => {
      setUploadedPhoto(null);
      setCroppedImage(null);
      dispatch(setAvatarImage(null));
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };
   
  return (
    <div className="bg-white rounded-[6px] p-5">
      <div className="row row-cols-5 gy-4">
        <div className="col">

        {!croppedImage    ? ( <label className="block">
              <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
          <div className="border-[2px] create_blank group flex flex-col items-center justify-center text-center border-dashed h-[176px] rounded-[6px]">
            <img
              src="/assets/images/svg/folder-plus.svg"
              alt="folder"
              className="group-hover:invert"
            />
            <p className="mt-2 text-sm text-black/50 group-hover:text-white">
              Upload Image
            </p>
          </div>
          </label>

        ) : (
            <div className="relative bg-[#2C3A9B]/20 rounded-[6px]  ">
              <button
                onClick={handleReupload}
                className="absolute top-2 right-2 bg-white text-xs text-[#2C3A9B] border border-[#CBD0DC] py-[6px] px-[12px] rounded"
              >
                Re-upload
              </button>
              <img
                src={croppedImage}
                alt="Uploaded"
                className="max-h-full max-w-full rounded"
              />
            </div>
          )}
          {cropPopupOpen && (
          <div className="crop-popup fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-4 w-[80%]">
              <h2 className="text-lg mb-4">Crop Image</h2>
              <Cropper
                src={uploadedPhoto}
                style={{ height: 400, width: "100%" }}
                ref={cropperRef}
                aspectRatio={1}
                guides={false}
              />
              <div className="flex justify-end mt-4">
                <button
                  className="bg-theme-color text-white px-4 py-2 rounded-lg"
                  onClick={handleCropImage}
                >
                  Upload
                </button>
                <button
                  className="ml-2 bg-gray-300 px-4 py-2 rounded-lg"
                  onClick={() => setCropPopupOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        </div>
        <div className="col">
          <img
            src="/assets/images/png/img3.png"
            alt="images"
            className="h-[194px] rounded-[6px]"
          />
        </div>
        <div className="col">
          <img
            src="/assets/images/png/img3.png"
            alt="images"
            className="h-[194px] rounded-[6px]"
          />
        </div>
        <div className="col">
          <img
            src="/assets/images/png/img3.png"
            alt="images"
            className= " h-[194px] rounded-[6px]"
          />
        </div>
        <div className="col">
          <img
            src="/assets/images/png/img3.png"
            alt="images"
            className=" h-[194px] rounded-[6px]"
          />
        </div>
        <div className="col">
          <img
            src="/assets/images/png/img3.png"
            alt="images"
            className=" h-[194px]  rounded-[6px]"
          />
        </div>
      </div>
    </div>
  );
};

export default AvtarPhotos;
