import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import store from './redux/store';
import queryClient from './hook/queryClient';

// axios.defaults.baseURL = 'http://localhost:5000/api/'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
      <QueryClientProvider client={queryClient}>
  <GoogleOAuthProvider clientId="585134430935-phldipmmuq40co64n1rlig17c7peingb.apps.googleusercontent.com">
  {/* <React.StrictMode> */}
    <App />
  {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
  </QueryClientProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
